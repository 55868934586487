class dateInput extends Component {

    static name() {
        return "date-input";
    }

    static componentName() {
        return "date-input";
    }

    getProps() {
        return ['value', 'label'];
    }

    data() {
        return {
            fp: null,
            id: this.getIdo.ui.genId(),
            internalValue: this.value ? moment(this.value).format("DD/MM/YYYY") : ''
        };
    }

    mounted() {
        return async function () {
            let self = this;

            let cnf = {
                //onChange: function (selectedDates, dateStr, instance) { /*fp.triggerOnChange(selectedDates, dateStr, instance)*/ },
                disableMobile: true, allowInput: true, dateFormat: 'd/m/Y', clickOpens: false
            }

            /*this.fp = flatpickr(`#${this.id}`, cnf);
            $(this.$refs.input).mask('00/00/0000')
            this.$refs.input.addEventListener("keyup", function (ev) {
                //console.log("KEYUP", ev.key)
                if (ev.key === 'ArrowDown') {
                    self.fp.open();
                }
            })*/
            await Vue.nextTick();
            //Materialize.updateTextField($(this.$refs.input))
        };
    }


    getWatch() {
        return {
            value: function (val) {
                //console.log("VAL", val)
                this.internalValue = val ? moment(val).format("DD/MM/YYYY") : ''
                this.fp.setDate(this.internalValue)
                //$(this.$refs.input).val(moment(val).format("DD/MM/YYYY"))
            }
        };
    }

    getMethods() {
        return {
            onInput: function (e) {
                this.internalValue = e.target.value
                this.$emit("input", e.target.value ? e.target.value : null)
            },
            openCalendar() {
                this.fp.open()
            }
        };
    }


    getTemplate() {
        return `<div class="input-field oo-date-field ">
                    <input :id="id" ref="input" type="text" :value="internalValue" @change="onInput">
                    <span @click="openCalendar" class="input-icon"></span>
                    <label :for="id" ><span v-if="label">{{tr(label)}}</span></label>
               </div>`;
    }
}

dateInput.registerComponent();

